<div class="tw-flex tw-flex-row tw-flex-wrap"
     *ngIf="form">

  <div class="tw-flex-1 tw-box-border">
    <div [formGroup]="form">

      <div class="header-row dp-sticky-item">
        <div class="global-errors">
          <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
        </div>

        <mat-form-field>
          <mat-label>Product Line</mat-label>
          <mat-select (selectionChange)="onPartLineChanged()"
                      formControlName="partLine"
                      required>
            <mat-option *ngFor="let line of (productLines$ | async)"
                        [value]="line.lineCode">
              {{line.lineCode}} - {{line.internalName}}
            </mat-option>
          </mat-select>

          <mat-error [depot-form-error]="form"
                     errorControlName="partLine">
          </mat-error>
        </mat-form-field>

        <mat-form-field [class.readonly]="isPutawayReadOnly$|async">
          <!--  -->
          <mat-label>Dealer Number</mat-label>
          <input (ngModelChange)="onDealerChange($event)"
                 matInput
                 formControlName="dealerNumber"
                 type="text"
                 minlength="5"
                 maxlength="5"
                 [readonly]="isPutawayReadOnly$|async"
                 required />
          <mat-hint>{{ (selectedDealer$ | async)?.name }}</mat-hint>
          <mat-error [depot-form-error]="form"
                     errorControlName="dealerNumber">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Date Arrived</mat-label>
          <mat-select [required]="(openWeightTickets$ | async)?.length > 0"
                      formControlName="dealerReturnWeightTicketId">
            <mat-option *ngFor="let weightTicket of (openWeightTickets$ | async)"
                        [value]="weightTicket.id">
              {{weightTicket.dealerReturn.dateArrived | date: 'yyyy-MM-dd'}} - {{weightTicket.dealerReturn.warehouse}}
            </mat-option>
          </mat-select>
          <mat-hint>{{dealerReturnScrap?.weightTicket?.pricePerIbs | currency}}</mat-hint>
          <mat-error [depot-form-error]="form"
                     errorControlName="dealerReturnWeightTicketId">
          </mat-error>

        </mat-form-field>

        @let returns = openWeightTickets$|async;
        <mat-form-field [ngClass]="{'tw-hidden': returns==null || returns.length === 0}">
          <mat-label>Size</mat-label>
          <mat-select [required]="returns != null && returns.length > 0"
                      formControlName="dealerReturnSize">
            <mat-option [value]="null"></mat-option>
            <mat-option value="small">Small</mat-option>
            <mat-option value="large">Large</mat-option>
          </mat-select>
          <mat-error [depot-form-error]="form"
                     errorControlName="dealerReturnSize">
          </mat-error>

        </mat-form-field>

        <mat-form-field>
          <mat-label>Locations</mat-label>
          <mat-select tabindex="-1"
                      (valueChange)="onFilterChange($event)">
            <mat-option [value]="null"></mat-option>
            <!-- <mat-option value="-2">Unpriced</mat-option> -->
            <mat-option value="-1"
                        *ngIf="form?.invalid === true">Show Errors</mat-option>
            <mat-option *ngFor="let location of (availableLocations$ | async)"
                        [value]="location">
              {{location}}
            </mat-option>
          </mat-select>
          <mat-hint [style.color]="(runningTotal$ | async) >= 30 ? '#EF6C00' : ''">
            {{runningTotal$ | async}} items in box {{lastRowLocation$ | async}}</mat-hint>
        </mat-form-field>

      </div>


      <div class="tw-flex tw-flex-col">
        @for (row of form.controls.rows.controls; let i = $index; track row.get("rowKey").value) {
        @let part = row.get('part$').value | async;
        <div class="item-row"
             [ngStyle]="{'order': sortByPartPrice() ? part?.internalDealerNetPrice == null ? -1 : (part?.internalDealerNetPrice*100).toFixed(0) : 0}"
             [ngClass]="[i % 2 == 0 ? 'item-row-even':'item-row-odd', (lastFocusRow$ | async)===i ? 'item-row-selected':'']"
             *ngIf="row.controls['hidden'].value === false || form.controls['rows'].controls.length-1 === i"
             (click)="lastFocusRow$.next(i)">
          <!--  -->
          <!-- {{sortByPartPrice() ? part?.internalDealerNetPrice == null ? -1 : (part?.internalDealerNetPrice*100).toFixed(0) : 0}} -->
          <app-putaway-edit-row [row]="row"
                                [form]="form"
                                [index]="i"
                                [lastFocusRow$]="lastFocusRow$"
                                (removeFormItem)="removeFormItem(i, row.get('rowKey').value)"
                                (rowBlur)="onRowBlur(i)"
                                (partChange)="onPartChange(row, i)" />


        </div>
        }
      </div>
    </div>
    <div id="bottom"></div>
  </div>


  <depot-camera id="depotCamera"
                class="tw-flex tw-flex-col tw-flex-wrap tw-m-2 tw-flex-1-full tw-box-border"
                style="max-width: 40%"
                (capture)="capture($event, lastFocusRow$.value)"></depot-camera>

</div>

<div class="dp-top-fab-container">

  <button (click)="saveForm(false)"
          mat-mini-fab
          matTooltip="Save Putaway"
          matTooltipPosition="below"
          color="accent"
          matBadgePosition="below before"
          matBadgeColor="warn"
          matBadgeOverlap="true"
          [class.no-pending-changes]="(pendingChanges$ | async)===0"
          [matBadge]="pendingChanges$ | async">
    <span class="fa fa-save"></span>
  </button>

  <button [disabled]="(imageSvc?.cameraDevices$ | async)?.length === 0"
          (click)="depotCamera?.onCapture()"
          type="button"
          mat-mini-fab
          matTooltip="Capture Image"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-camera"></span>
  </button>

  <button (click)="addFormRow(null)"
          type="button"
          mat-mini-fab
          matTooltip="Add Row"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-plus"></span>
  </button>
  <button (click)="exportData()"
          [disabled]="form?.valid === false || form?.dirty === true"
          type="button"
          mat-mini-fab
          matTooltip="Export"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-download"></span>
  </button>

  <button (click)="showHelp()"
          type="button"
          mat-mini-fab
          matTooltip="Help"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-question"></span>
  </button>

  <button (click)="showScrapDialog()"
          [disabled]="form?.valid === false || form?.dirty === true || !form?.get('dealerReturnWeightTicketId').value"
          type="button"
          mat-mini-fab
          matTooltip="Scrap Info"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-recycle"></span>
  </button>
  @if(authService.isInRole$.SiteAdmin | async){
  <button (click)="toggleSortByPartPrice()"
          type="button"
          mat-mini-fab
          matTooltip="Sort By Price"
          matTooltipPosition="below"
          [color]="sortByPartPrice() ? 'warn': 'accent'">
    <span class="fa fas fa-compress-arrows-alt"></span>
  </button>
  }
</div>
